import { useApplicationContext } from '@/context/ApplicationContext';
import { TASK } from '../i18n/constants';
import useTranslation from '../i18n/useTranslation';

type ExtendItemType = { createdBy?: { id: string }; assignees?: { id: string }[] };

const LIST_TYPES = {
  ASSIGNED_TO_ME: 'assignedToMe',
  CREATED_BY_ME: 'createdByMe',
  OTHERS: 'others',
} as const;

export const ACCORDION_LABEL_KEYS_MAP: Record<string, string> = {
  [LIST_TYPES.ASSIGNED_TO_ME]: 'assigned-to-me',
  [LIST_TYPES.CREATED_BY_ME]: 'created-by-me',
  [LIST_TYPES.OTHERS]: 'others',
};

export const LIST_ACCORDION_TYPES = [
  LIST_TYPES.ASSIGNED_TO_ME,
  LIST_TYPES.CREATED_BY_ME,
  LIST_TYPES.OTHERS,
];

export const useListAccordions = (namespace = TASK) => {
  const { me } = useApplicationContext();
  const { t, t_ns } = useTranslation(namespace);

  const getAccordionLabel = (key: string): string => {
    const commonKeys: string[] = [LIST_TYPES.OTHERS];
    const labelKey = ACCORDION_LABEL_KEYS_MAP[key] || key;

    if (commonKeys.includes(labelKey)) return t(labelKey);

    return t_ns(labelKey);
  };

  const mapItemsByAccordionsType = <T extends ExtendItemType>(items: T[]): Map<string, T[]> => {
    const itemsByType = new Map<string, T[]>();
    const userId = me?.id;

    for (const item of items) {
      const { createdBy, assignees } = item;

      const isCreatedByCurrentUser = createdBy?.id === userId;
      const isAssignedToCurrentUser = assignees?.some((assignee) => assignee.id === userId);

      let itemType: string = LIST_TYPES.OTHERS;

      if (isAssignedToCurrentUser) {
        itemType = LIST_TYPES.ASSIGNED_TO_ME;
      } else if (isCreatedByCurrentUser) {
        itemType = LIST_TYPES.CREATED_BY_ME;
      }

      const existingItems = itemsByType.get(itemType) || [];
      existingItems.push(item);
      itemsByType.set(itemType, existingItems);
    }

    return itemsByType;
  };

  return {
    mapItemsByAccordionsType,
    getAccordionLabel,
  };
};
